<template>
  <div class="contentGestionFactureCommerciaux">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <b-form-checkbox
            v-show="switchMonth == false"
            v-model="switchMonth"
            @change="handleChangeSwitch('switchWeek')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Mois </b>
          </b-form-checkbox>
          <b-form-checkbox
            v-show="switchWeek == false"
            v-model="switchWeek"
            @change="handleChangeSwitch('switchMonth')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Semaine </b>
          </b-form-checkbox>
        </div>
        <div class="box-label-champ mr-2" v-if="switchMonth == true">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterMonth"
            :options="getListMonth"
            @change="filterTable"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2" v-if="switchWeek == true">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChangeSemaine"
          ></b-form-select>
        </div>
        <!-- <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Type</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterType"
            :options="[{ value: null, text: 'tous' }, ...ComputedgetAllType]"
            value-field="value"
            text-field="text"
            @change="handleChangeType"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Utilisateur</span>
          </div>

          <SelectComponent
            :options="ComputedgetAllEmployee"
            :value="filterUser"
            label="full_name"
            champName="utilisateur"
            filterName="filterUser"
            :change="handleChange"
            :track-by="'full_name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style bg-select-vue w-1"
          />
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterDepot"
            :options="ComputedgetAllDepot"
            value-field="id"
            text-field="name"
            @change="handleChange"
          ></b-form-select>
        </div> -->
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterValidation"
            :options="validationList"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div v-if="getChargementPaieCommerciaux" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="body-box-rapport">
      <palatte-color />
      <div class="table-rapport-style w-100-p">
        <b-table
          responsive
          show-empty
          id="my-table"
          class="custom-table-style tableGestionFactureCommerciaux  table-header "
          :items="computedgetuserSalary"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(statut)="data">
            {{ computedValidation(data.item) | formatStatut }}
          </template>
          <template v-slot:cell(color)> </template>
          <template v-slot:cell(name)="data">
            {{ data.item.employee.full_name }}
          </template>
          <template v-slot:cell(mail)="data">
            {{ data.item.employee.email }}
          </template>
          <template v-slot:cell(type)="data">
            {{ data.item.type }}
          </template>
          <template v-slot:cell(equipe)="data">
            {{ data.item.employee.team }}
          </template>
          <template v-slot:cell(depot_employee)="data">
            {{ data.item.employee.depot }}
          </template>
          <template v-slot:cell(semaine)="data">
            {{ data.item.semaine | formatSemaine }}
          </template>

          <template v-slot:cell(variable_vmc)="data">
            {{ data.item.variable_vmc }}
          </template>
          <template v-slot:cell(variable_pac)="data">
            {{ data.item.variable_pac }}
          </template>
          <template v-slot:cell(variable_source)="data">
            {{ data.item.variable_source }}
          </template>
          <!-- <template v-slot:cell(source)="data">
            <div class="List">
              <span
                v-for="itemSource in data.item.source"
                :key="itemSource.index"
              >
                -{{ itemSource.name }}
              </span>
            </div>
          </template>
          <template v-slot:cell(variable_depot)="data">
            {{ data.item.variable_depot }}
          </template> -->
          <!-- <template v-slot:cell(depot)="data">
            <div class="List">
              <span v-for="itemDepot in data.item.depot" :key="itemDepot.index">
                -{{ itemDepot.name }}
              </span>
            </div>
          </template> -->
          <template v-slot:cell(IGD)="data">
            {{ data.item.IGD }}
          </template>
          <template v-slot:cell(primeE)="data">
            {{ data.item.additional_prime }}
          </template>
          <template v-slot:cell(totalm2)="data">
            {{ data.item.totalm2 }}
          </template>
          <template v-slot:cell(fix)="data">
            {{ data.item.fix }}
          </template>

          <template v-slot:cell(prime_qualite)="data">
            {{ data.item.prime_qualite }}
          </template>
          <template v-slot:cell(prime_objectif)="data">
            {{ data.item.prime_objectif }}
          </template>
          <template v-slot:cell(prime_sav)="data">
            {{ data.item.prime_sav }}
          </template>
          <template v-slot:cell(other_prime_name)="data">
            {{ data.item.other_prime_name }}
          </template>
          <template v-slot:cell(other_prime_value)="data">
            {{ data.item.other_prime_value }}
          </template>
          <template v-slot:cell(deducation)="data">
            {{ data.item.deducation }}
          </template>
          <template v-slot:cell(TH_total)="data">
            {{ data.item.TH_total }}
          </template>

          <template v-slot:cell(total)="data">
            {{ data.item.total }}
          </template>
          <template v-slot:cell(m2previsite)="data">
            {{ data.item.m2previsite }}
          </template>
          <template v-slot:cell(m2vente)="data">
            {{ data.item.m2vente }}
          </template>
          <template v-slot:cell(m2previsitempr)="data">
            {{ data.item.m2previsitempr }}
          </template>
          <template v-slot:cell(m2ventempr)="data">
            {{ data.item.m2ventempr }}
          </template>
          <template v-slot:cell(prixM2vente)="data">
            {{ data.item.prixM2vente }}
          </template>
          <template v-slot:cell(prixM2previsite)="data">
            {{ data.item.prixM2previsite }}
          </template>
          <template v-slot:cell(prixM2venteMPR)="data">
            {{ data.item.prixM2venteMPR }}
          </template>
          <template v-slot:cell(prixM2previsiteMPR)="data">
            {{ data.item.prixM2previsiteMPR }}
          </template>
          <template v-slot:cell(venteENeuro)="data">
            <div class="pop-up-details">
              <div class="value">{{ data.item.venteENeuro }}</div>
              <TooltipInput
                placement="right-start"
                :project="data.item"
                :popUp="'venteENeuro'"
              >
                <div class="title-container">
                  <span><font-awesome-icon icon="info-circle"/></span>
                </div>
              </TooltipInput>
            </div>
          </template>
          <template v-slot:cell(previsiteENeuro)="data">
            <div class="pop-up-details">
              <div class="value">
                {{ data.item.previsiteENeuro }}
              </div>
              <TooltipInput
                placement="right-start"
                :project="data.item"
                :popUp="'previsiteENeuro'"
              >
                <div class="title-container">
                  <span><font-awesome-icon icon="info-circle"/></span>
                </div>
              </TooltipInput>
            </div>
          </template>

          <template v-slot:cell(m2_iti)="data">
            {{ data.item.m2_iti }}
          </template>
          <template v-slot:cell(m2_isloer)="data">
            {{ data.item.m2_isloer }}
          </template>

          <template v-slot:cell(m2_iti_3_euro)="data">
            {{ data.item.m2_iti_3_euro }}
          </template>
          <template v-slot:cell(m2_iti_2_euro)="data">
            {{ data.item.m2_iti_2_euro }}
          </template>
          <template v-slot:cell(m2_mpr)="data">
            {{ data.item.m2_mpr }}
          </template>
          <template v-slot:cell(m2_rampant_mpr)="data">
            {{ data.item.m2_rampant_mpr }}
          </template>

          <template v-slot:cell(m2_iti_mpr)="data">
            {{ data.item.m2_iti_mpr }}
          </template>
          <template v-slot:cell(prix_rampant_mpr)="data">
            {{ data.item.prix_rampant_mpr }}
          </template>
          <template v-slot:cell(prix_iti_mpr)="data">
            {{ data.item.prix_iti_mpr }}
          </template>
          <template v-slot:cell(total_mpr_en_euro)="data">
            {{ data.item.total_mpr_en_euro }}
          </template>
          <template v-slot:cell(total_payer_1_euro)="data">
            {{ data.item.total_payer_1_euro }}
          </template>
          <template v-slot:cell(total_payer_3_euro)="data">
            {{ data.item.total_payer_3_euro }}
          </template>
          <template v-slot:cell(total_payer_2_euro)="data">
            {{ data.item.total_payer_2_euro }}
          </template>

          <template v-slot:cell(total_iti_payer)="data">
            {{ data.item.total_iti_payer }}
          </template>
          <template v-slot:cell(total_poseur)="data">
            {{ data.item.total }}
          </template>
          <template v-slot:cell(m2_sedentaire_mpr)="data">
            {{ data.item.m2_sedentaire_mpr }}
          </template>
          <template v-slot:cell(m2_sedentaire_iso)="data">
            {{ data.item.m2_sedentaire_iso }}
          </template>
          <template v-slot:cell(TH_total_Confirmateur)="data">
            {{ data.item.TH_total }}
          </template>
          <template v-slot:cell(sedentaire_iso_en_euro)="data">
            {{ data.item.sedentaire_iso_en_euro }}
          </template>
          <template v-slot:cell(sedentaire_mpr_en_euro)="data">
            {{ data.item.sedentaire_mpr_en_euro }}
          </template>
          <template v-slot:cell(total_Confirmateur)="data">
            {{ data.item.total }}
          </template>

          <template v-slot:cell(prix_iso_interne)="data">
            {{ data.item.prix_iso_interne }}
          </template>
          <template v-slot:cell(prix_iso_externe)="data">
            {{ data.item.prix_iso_externe }}
          </template>
          <template v-slot:cell(prix_mpr_interne)="data">
            {{ data.item.prix_mpr_interne }}
          </template>
          <template v-slot:cell(prix_mpr_externe)="data">
            {{ data.item.prix_mpr_externe }}
          </template>

          <!-- <template v-slot:cell(montant_depot)="data">
            {{ data.item.montant_depot }}
          </template>
          <template v-slot:cell(montant_source)="data">
            {{ data.item.montant_source }}
          </template>
          <template v-slot:cell(number_depot)="data">
            {{ data.item.number_depot }}
          </template>
          <template v-slot:cell(number_source)="data">
            {{ data.item.number_source }}
          </template> -->
          <template v-slot:cell(payed_at)="data">
            {{ data.item.payed_at }}
          </template>
          <template v-slot:cell(total-m2-€)="data">
            {{ data.item.total_variables }}
          </template>
          <template v-slot:cell(commentaire)="data">
            {{ data.item.comment }}
          </template>
          <template v-slot:cell(upload)="data">
            <div
              class="container"
              @drop="handleFileDrop($event, data.item)"
              v-if="
                computedValidation(data.item) !== 'paye' &&
                  computedValidation(data.item) !== 'encore de paiement' &&
                  switchMonth == true
              "
            >
              <div class="file-wrapper" title="Upload facture">
                <input
                  type="file"
                  name="file-input"
                  accept="application/pdf,application/vnd.ms-excel"
                  @change="handleFileInput($event, data.item)"
                />
                <div class="upload_label" v-if="!data.item.file">
                  <font-awesome-icon icon="cloud-upload-alt" />
                </div>
                <div class="upload_label" v-if="data.item.file">
                  <font-awesome-icon
                    icon="file-pdf"
                    :pulse="getChargementUploadFactureRegie"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-slot:cell(download)="data">
            <b-button
              v-if="data.item.type == 'Poseur' || data.item.type == 'Co poseur'"
              size="sm"
              class="iconDownload"
              title="Appel à facturation"
              @click.prevent.stop="
                download({ item: data.item, project_type: 'poseur' })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(appel_facturation_iso)="data">
            <b-button
              v-if="
                data.item.type != 'Poseur' &&
                  data.item.type != 'Co poseur' &&
                  data.item.ISO_download == true
              "
              size="sm"
              class="iconDownload"
              title="Appel à facturation"
              @click.prevent.stop="
                download({ item: data.item, project_type: 'ISO' })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(appel_facturation_interne)="data">
            <b-button
              v-if="
                data.item.type == 'Commercial sedentaire' &&
                  data.item.interne == true
              "
              size="sm"
              class="iconDownload"
              title="Appel à facturation"
              @click.prevent.stop="
                download({ item: data.item, project_type: 'interne' })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(appel_facturation_externe)="data">
            <b-button
              v-if="
                data.item.type == 'Commercial sedentaire' &&
                  data.item.externe == true
              "
              size="sm"
              class="iconDownload"
              title="Appel à facturation"
              @click.prevent.stop="
                download({ item: data.item, project_type: 'externe' })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(appel_facturation_th)="data">
            <b-button
              v-if="
                data.item.type != 'Poseur' &&
                  data.item.type != 'Co poseur' &&
                  data.item.TH_download == true
              "
              size="sm"
              class="iconDownload"
              title="Appel à facturation"
              @click.prevent.stop="
                download({ item: data.item, project_type: 'TH' })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="
              switchMonth == true ? 1 : getTotalRowUserSalaryCommerciaux
            "
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  data() {
    return {
      filterUser: { full_name: 'tous', id: null },
      filterType: null,
      filterDepot: null,
      filteryear: null,
      filterSemaine: null,
      filterValidation: null,
      filterMonth: 1,
      ListYear: [],
      ListWeek: [],
      page: 1,
      perPage: 10,
      validationList: [
        { value: null, text: 'tous' },
        { value: 'en attend', text: 'En cours de validation' },
        { value: 'refuse', text: 'Réfusé' },
        { value: 'valid', text: 'appel à facturation validé' },
        {
          value: 'encore de verification',
          text: 'En cours de vérification'
        },
        { value: 'facture valid', text: ' facture validé' },
        { value: 'facture refuse', text: ' facture réfusé' },

        { value: 'encore de paiement', text: 'En cours de paiement' },
        { value: 'paye', text: 'Payé' }
      ],
      fields: [
        { key: 'variable_vmc', label: 'Variable Vmc' },
        { key: 'variable_pac', label: 'Variable PAC' },
        // { key: 'variable_source', label: 'Variable source' },
        // { key: 'source', label: 'Sources' },
        // { key: 'variable_depot', label: 'Variable dépôt' },
        // { key: 'depot', label: 'Dépôts' },
        {
          key: 'IGD',
          label: 'IGD ',
          headerTitle: 'Indemnité Grand deplacement '
        },
        { key: 'prime_qualite', label: 'Prime Qualité' },
        { key: 'prime_objectif', label: 'Prime Objectif' },
        { key: 'prime_sav', label: 'Prime Sav' },
        { key: 'primeE', label: 'Prime exceptionnelle' },
        { key: 'other_prime_name', label: 'Autre Prime (Nom)' },
        { key: 'other_prime_value', label: 'Autre Prime (Montant)' },
        { key: 'deducation', label: 'Déducation' },
        // { key: 'montant_depot', label: 'Montant dépôt' },
        // { key: 'montant_source', label: 'Montant source' },
        // { key: 'number_depot', label: 'Numéro dépôt' },
        // { key: 'number_source', label: 'Numéro source' },

        { key: 'commentaire', label: 'Commentaire' },
        {
          key: 'payed_at',
          label: 'Date de paiement'
        },

        {
          key: 'statut',
          label: 'Statut'
        },
        {
          key: 'appel_facturation_interne',
          label: 'Appel à facturation interne ISO'
        },
        {
          key: 'appel_facturation_externe',
          label: 'Appel à facturation externe ISO'
        },
        {
          key: 'appel_facturation_iso',
          label: 'Appel à facturation ISO'
        },
        {
          key: 'appel_facturation_th',
          label: 'Appel à facturation TH'
        },
        {
          key: 'download',
          label: 'Appel  à facturation'
        },
        {
          key: 'upload',
          label: 'Upload facture',
          thClass: 'thpaiementR',
          tdClass: 'tdpaiementR'
        }
      ],
      fieldsPoseur: [
        //Poseur && Co-poseur
        { key: 'm2_iti', label: 'M² ITI' },
        { key: 'm2_isloer', label: 'Total M² Isolé2' },
        { key: 'm2_iti_3_euro', label: 'M² ITI + (3€)' },
        { key: 'm2_iti_2_euro', label: 'M² ITI + (2€)' },
        { key: 'm2_mpr', label: 'Total M² MPR' },
        { key: 'm2_rampant_mpr', label: 'Total M² Rampant MPR' },
        { key: 'm2_iti_mpr', label: 'Total M² Murs ITI MPR' },
        { key: 'prix_rampant_mpr', label: 'Prix Rampants MPR' },
        { key: 'prix_iti_mpr', label: 'Prix Murs MPR' },
        { key: 'total_mpr_en_euro', label: 'Montant MPR en €' },
        { key: 'total_payer_1_euro', label: 'Montant à payer (1€)' },
        { key: 'total_payer_3_euro', label: 'Prix ITI + / m² (3€)' },
        { key: 'total_payer_2_euro', label: 'Prix ITI + / m² (2€)' },
        { key: 'total_iti_payer', label: 'Montant ITI + à payer ' },
        { key: 'total_poseur', label: 'Total à payer' }
      ],
      fieldsPrévisiteur: [
        //prévisiteur && Commercial terrain

        { key: 'm2previsite', label: 'M² Prévisite' },
        { key: 'm2vente', label: 'M² Vente' },
        { key: 'm2previsitempr', label: 'M² Prévisite MPR' },
        { key: 'm2ventempr', label: 'M² Vente MPR' },
        { key: 'TH_total', label: 'TH 164' },
        { key: 'prixM2vente', label: 'Prix M² Vente' },
        { key: 'prixM2previsite', label: 'Prix M² Prévisite' },
        { key: 'prixM2venteMPR', label: 'Prix M² Vente MPR' },
        { key: 'prixM2previsiteMPR', label: 'Prix M² Prévisite MPR' },
        { key: 'venteENeuro', label: 'Vente en €' },
        { key: 'previsiteENeuro', label: 'Prévisite en €' },
        {
          key: 'total',
          label: 'Total en €'
        }
      ],
      fieldsSedentaire: [
        //Sédentaire && Confirmateur

        { key: 'm2_sedentaire_mpr', label: 'Total M² MPR' },
        { key: 'm2_sedentaire_iso', label: 'Total M² sans MPR' },
        { key: 'TH_total_Confirmateur', label: 'Montant Bar TH en €' },
        { key: 'sedentaire_iso_en_euro', label: 'Total sans MPR en €' },
        { key: 'sedentaire_mpr_en_euro', label: 'Total MPR en €' },
        { key: 'total_Confirmateur', label: 'Total  en € FINAL' },
        { key: 'fix', label: 'Montant fixe' },
        { key: 'prix_iso_interne', label: 'Prix sans MPR interne' },
        { key: 'prix_iso_externe', label: 'Prix sans MPR Externe' },
        { key: 'prix_mpr_interne', label: 'Prix MPR interne' },
        { key: 'prix_mpr_externe', label: 'Prix MPR Externe' },
        {
          key: 'total',
          label: 'Total en €'
        }
      ],
      fieldsSticky: [
        { key: 'color', label: '', tdClass: 'color', thClass: 'th-color' },
        {
          key: 'name',
          label: 'Nom',
          thClass: 'th-name-fixed',
          tdClass: 'td-name-fixed'
        },
        { key: 'mail', label: 'E-mail' },
        { key: 'type', label: 'Type' },
        { key: 'semaine', label: 'Semaine' },
        {
          key: 'totalm2',
          label: 'Total M²'
        }
      ],
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      switchMonth: false,
      switchWeek: true
    };
  },
  methods: {
    ...mapActions([
      'getSalaryCommerciauxFiltred',
      'getDepotForUser',
      'newComment',
      'downloadFactureCommerciauxPDF',
      'getEmployeeForUserWithType',
      'getOnlineType',
      'getOnlineSalaryEmployeeBytype',
      'uploadFactureCommerciaux'
    ]),
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (this.computedValidation(item) == 'refuse') return 'ligneRefuse';
      else if (this.computedValidation(item) == 'valid') return 'ligneAccepted';
      else if (this.computedValidation(item) == 'encore de verification')
        return 'ligneVerification';
      else if (this.computedValidation(item) == 'facture valid')
        return 'ligneValidF';
      else if (this.computedValidation(item) == 'facture refuse')
        return 'ligneRefuseF';
      else if (this.computedValidation(item) == 'encore de paiement')
        return 'ligneEnAttend';
      else if (this.computedValidation(item) == 'paye') return 'lignePaye';
      else return 'ligneEnCourDeValidation';
    },
    hendleGetTypeAndUser() {
      this.getOnlineType({
        year: this.filteryear,
        semaine: this.filterSemaine
      });
      this.getOnlineSalaryEmployeeBytype({
        year: this.filteryear,
        semaine: this.filterSemaine,
        type: this.filterType
      });
    },

    filterTable() {
      this.getSalaryCommerciauxFiltred({
        semaine: this.filterSemaine,
        employee_id: this.filterUser,
        year: this.filteryear,
        page: this.page,
        validation: this.filterValidation,
        depot: this.filterDepot,
        type: this.filterType,
        perPage: this.perPage,
        month: this.filterMonth,
        switchMonth: this.switchMonth,
        switchWeek: this.switchWeek
      });
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      sessionStorage.setItem('page-validation-salaire-commerciaux', this.page);
      localStorage.setItem(
        'Validation-salaire-Filter-Commerciaux',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterUser: this.filterUser,
          filterValidation: this.filterValidation,
          filterDepot: this.filterDepot,
          filterType: this.filterType
        })
      );
      this.hendleGetTypeAndUser();
      this.filterTable();
    },
    handleChangeSemaine(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;
      sessionStorage.setItem('page-validation-salaire-commerciaux', this.page);
      localStorage.setItem(
        'Validation-salaire-Filter-Commerciaux',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterUser: this.filterUser,
          filterValidation: this.filterValidation,
          filterDepot: this.filterDepot,
          filterType: this.filterType
        })
      );
      this.hendleGetTypeAndUser();

      this.filterTable();
    },
    handleChangeYear() {
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;
      var Filters = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux')
      );
      Filters.filteryear = this.filteryear;
      Filters.filterUser = this.filterUser;
      Filters.filterType = this.filterType;

      localStorage.setItem(
        'Validation-salaire-Filter-Commerciaux',
        JSON.stringify(Filters)
      );
      this.page = 1;
      sessionStorage.setItem('page-validation-salaire-commerciaux', this.page);
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.hendleGetTypeAndUser();

      this.filterTable();
    },
    handleChangeType() {
      this.page = 1;
      this.filterUser = { full_name: 'tous', id: null };
      sessionStorage.setItem('page-validation-salaire-commerciaux', this.page);
      localStorage.setItem(
        'Validation-salaire-Filter-Commerciaux',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterUser: this.filterUser,
          filterValidation: this.filterValidation,
          filterDepot: this.filterDepot,
          filterType: this.filterType
        })
      );
      this.getOnlineSalaryEmployeeBytype({
        year: this.filteryear,
        semaine: this.filterSemaine,
        type: this.filterType
      });
      this.getDepotForUser({ type: this.filterType });
      this.filterTable();
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-validation-salaire-commerciaux', this.page);
      this.filterTable();
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-validation-salaire-commerciaux', this.page);
      this.filterTable();
    },
    download(item) {
      this.downloadFactureCommerciauxPDF({
        item: item.item,
        year: this.filteryear,
        project_type: item.project_type
      });
    },
    async handleFileDrop(e, item) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        item.file = f;
      });
      if (this.switchMonth == true) {
        this.$alert(
          '',
          'Cette facture va être attribué pour toutes les semaines de mois ' +
            // +'de janvier'
            this.filterMonth,
          'info'
        ).then(async () => {
          const responce = await this.uploadFactureCommerciaux(item);
          if (responce) {
            this.$alert('', 'facture uploadé', 'success');
          } else {
            this.$alert(this.getErrorUploadFacture, '', 'error');
          }
        });
      } else {
        const responce = await this.uploadFactureCommerciaux(item);
        if (responce) {
          this.$alert('', 'facture uploadé', 'success');
        } else {
          this.$alert(this.getErrorUploadFacture, '', 'error');
        }
      }
    },
    async handleFileInput(e, item) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        item.file = f;
      });
      if (this.switchMonth == true) {
        this.$alert(
          '',
          'Cette facture va être attribué pour toutes les semaines de mois ' +
            // +'de janvier'
            this.filterMonth,
          'info'
        ).then(async () => {
          const responce = await this.uploadFactureCommerciaux(item);
          if (responce) {
            this.$alert('', 'facture uploadé', 'success');
          } else {
            this.$alert(this.getErrorUploadFacture, '', 'error');
          }
        });
      } else {
        const responce = await this.uploadFactureCommerciaux(item);
        if (responce) {
          this.$alert('', 'facture uploadé', 'success');
        } else {
          this.$alert(this.getErrorUploadFacture, '', 'error');
        }
      }
    },
    handleChangeSwitch(item) {
      if (item == 'switchMonth') {
        this.switchMonth = false;
      } else {
        this.switchWeek = false;
      }
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;

      this.hendleGetTypeAndUser();
      this.filterTable();
    }
  },
  computed: {
    ...mapGetters([
      'getAllEmployee',
      'getChargementPaieCommerciaux',
      'getTotalRowUserSalaryCommerciaux',
      'getSalaryCommerciauxTableValidation',
      'getDepots',
      'getOnlineTypeEmloyees',
      'getActiveEmployyes',
      'getUserData',
      'getChargementUploadFactureRegie',
      'getListMonth'
    ]),
    ComputedgetAllType() {
      return this.getOnlineTypeEmloyees.map(item => {
        return {
          value: item,
          text: item
        };
      });
    },
    ComputedListWeek() {
      return [{ value: null, text: 'tous' }, ...this.ListWeek];
    },
    ComputedgetAllEmployee() {
      return [{ id: null, full_name: 'tous' }, ...this.getActiveEmployyes];
    },
    ComputedgetAllDepot() {
      return [{ id: null, name: 'tous' }, ...this.getDepots];
    },
    computedgetuserSalary() {
      if (this.filterValidation != null) {
        return this.getSalaryCommerciauxTableValidation.filter(
          item => item.validation == this.filterValidation
        );
      }
      return this.getSalaryCommerciauxTableValidation;
    },

    computedColor() {
      return function(item) {
        if (item.validation == 'refuse') return 'red';
        else if (item.validation == 'valid') return 'ligneAlert';
        else return 'ligneNormale';
      };
    },
    computeReponsableDepot: function() {
      return this.getUserData && this.getUserData.role === 'responsable_depot';
    },
    computedValidationList() {
      return [
        {
          value: 'en attend',
          full_name: 'En cours de validation',
          $isDisabled: true
        },
        { value: 'refuse', full_name: 'Réfusé' },
        { value: 'valid', full_name: 'Validé lappel a facturation' },
        {
          value: 'encore de verification',
          full_name: 'En cours de vérification'
        },
        { value: 'facture valid', full_name: 'Validé la facture' },
        { value: 'facture refuse', full_name: 'Réfusé la facture' },
        { value: 'encore de paiement', full_name: 'En cours de paiement' },

        { value: 'paye', full_name: 'Payé' }
      ];
    },
    computedFields() {
      if (
        this.getUserData &&
        (this.getUserData.role === 'Co poseur' ||
          this.getUserData.role === 'Poseur')
      ) {
        let fieldsNews = this.fields.filter(
          item =>
            item.key != 'appel_facturation_iso' &&
            item.key != 'appel_facturation_th'
        );
        return [...this.fieldsSticky, ...this.fieldsPoseur, ...fieldsNews];
      }
      if (
        this.getUserData &&
        (this.getUserData.role === 'Pre visiteur' ||
          this.getUserData.role === 'Commercial terrain')
      ) {
        let fieldsNews = this.fields.filter(item => item.key != 'download');

        return [...this.fieldsSticky, ...this.fieldsPrévisiteur, ...fieldsNews];
      }
      if (
        this.getUserData &&
        this.getUserData.role === 'Commercial sedentaire'
      ) {
        let fieldsNews = this.fields.filter(item => item.key != 'download');

        return [...this.fieldsSticky, ...this.fieldsSedentaire, ...fieldsNews];
      }
      return [...this.fieldsSticky, ...this.fields];
    },
    computedValidation: function() {
      return function(data) {
        const indexOfFirst = data.validation.split(',');
        const found = indexOfFirst.filter(
          element => element == indexOfFirst[0]
        );
        if (indexOfFirst.length == found.length) {
          return indexOfFirst[0];
        }
        return;
      };
    }
  },
  components: {
    // SelectComponent: () => import('../SelectComponent.vue'),
    palatteColor: () => import('../component/paletteColor.vue'),
    TooltipInput: () => import('../component/TooltipInput.vue')
  },
  filters: {
    formatSemaine: value => {
      if (value.substr(0, 1) === ',') {
        return value.substr(1);
      } else {
        return value;
      }
    },
    formatStatut: value => {
      switch (value) {
        case 'en attend':
          return 'En cours de validation';
        case 'refuse':
          return 'Réfusé';
        case 'valid':
          return 'Appel à facturation validé';
        case 'encore de verification':
          return 'En cours de vérification';
        case 'facture valid':
          return 'Facture validé';
        case 'facture refuse':
          return 'Facture réfusé';
        case 'encore de paiement':
          return 'En cours de paiement';
        case 'paye':
          return 'Payé';
        default:
          return value;
      }
    }
  },
  mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('Validation-salaire-Filter-Commerciaux')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux')
      ).filteryear;
      this.filterSemaine = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux')
      ).filterSemaine;
      this.filterUser = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux')
      ).filterUser;
      this.filterValidation = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux')
      ).filterValidation;
      this.filterDepot = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux')
      ).filterDepot;
      this.filterType = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux')
      ).filterType;
    } else {
      this.filteryear = courantYear;
      this.filterSemaine = 's' + moment().isoWeek();
      localStorage.setItem(
        'Validation-salaire-Filter-Commerciaux',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterUser: this.filterUser,
          filterValidation: this.filterValidation,
          filterDepot: this.filterDepot,
          filterType: this.filterType
        })
      );
    }
    if (sessionStorage.getItem('page-validation-salaire-commerciaux')) {
      this.page = sessionStorage.getItem('page-validation-salaire-commerciaux');
    } else {
      sessionStorage.setItem('page-validation-salaire-commerciaux', this.page);
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    // this.getOnlineType({
    //   year: this.filteryear,
    //   semaine: this.filterSemaine
    // });
    // this.getOnlineSalaryEmployeeBytype({
    //   year: this.filteryear,
    //   semaine: this.filterSemaine,
    //   type: this.filterType
    // });
    // this.getDepotForUser({ type: this.filterType });
    this.filterTable();
  }
};
</script>

<style scoped lang="scss">
.tableGestionFactureCommerciaux {
  height: calc(100vh - 175px);
  max-height: calc(100vh - 175px);
  margin-bottom: 0px;
}
.body-box-rapport {
  height: calc(100vh - 106px);
}
.sub-menu .body .body-box-rapport {
  height: calc(100vh - 175px);
}
.List {
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.iconDownload {
  padding: 1px 4px;
  color: #6c757d;
  background-color: transparent;
  border-color: #6c757d;
  &:hover {
    background-color: #6c757d;
    border-color: white;
    color: white;
  }
}

.contentGestionFactureCommerciaux {
  margin: 0px;
  width: 100%;
  padding: 10px;
  display: block;
  height: calc(100vh - 60px);
  overflow: hidden;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .container {
    height: 28px;
    width: 28px;
    padding: 2px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px solid #6c757d;
      border-radius: 50%;
      color: #6c757d;
      background-color: transparent;
      &:hover {
        background-color: #6c757d;
        border-color: white;
        color: white;
      }
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 14px;
        p {
          font-size: 9px;
        }
      }
    }
  }
}
</style>
